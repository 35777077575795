.footer {
	position: relative;
	height: 0;
	.col-wrap {
		z-index: 2;
		height: 325px;
	}
	
	.logo {
		img {
			display: block;
		}
	}

	h2{
		padding-top: 112px;
		font-size:34px;
		font-family:PingFang SC;
		font-weight:500;
		color:rgba(51,54,85,1);
		line-height:50px;
		padding-bottom: 36px;
	}
	h3{
		font-size:16px;
		font-family:PingFang SC;
		font-weight:400;
		color:rgba(98,100,117,1);
	}

	.crp {
		position: absolute;
		top: 82px;
		right: 0;
		line-height: 1;
		text-align: center;
		font-size: 16px;
		color: #637178;
	}

	.copyright{
		width: 1200px;
		text-align: center;	
		padding-top: 50px;

		a{
			color: blue
		}
	}
}
