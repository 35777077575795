.header {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    height: 62px;
    background: white;

    .title{
        font-size: 16px;
        font-weight: 400;
        color: #161411;
        position: absolute;
        line-height: 62px;
    }
    .logo {
        position: absolute;
        top: 0;
        left: 0;

        a {
            display: block;
            width: 252px;
            height: 52px;
            background: url(../../images/common/logo.png) left center no-repeat;
            
            text-indent: -999px;
            overflow: hidden;
        }
    }

    .nav {
        // margin-right: 80px;
        text-align: right;        

        a {
            display: block;
            padding: 21px 42px 20px 42px;
            line-height: 1;
            font-size: 16px;
            color: #626475;
            // line-height: 62px;
            &:hover {
                color: #296EED;
            }
        }

        li {
            position: relative;
            display: inline-block;

            &::before {
                position: absolute;
                bottom: 0px;
                left: 50%;
                content: ' ';

                width: 0;
                height: 2px;
                // background-color: #fff;

                transform: translate(-50%, 0);
                transition: width .3s;
            }

            &:hover {
                &::before {
                    width: 44px;
                }
            }

            // &:last-child {
            //     a {
            //         padding-right: 0;
            //     }
            // }
        }
    }

    .lang {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 10;

        font-size: 12px;
        color: #fff;

        > span {
            display: block;

            em {
                font-size: 16px;
                color: $color-active;
            }

            i {
                font-style: normal;
            }
        }

        ul {
            display: none;
            position: absolute;
            top: 100%;
            right: 0;

            width: 80px;

            li {
                font-size: 16px;
                line-height: 32px;
                text-align: right;

                cursor: pointer;

                &:hover,
                &.active {
                    color: $color-active;
                }
            }
        }

        &:hover {
            ul {
                display: block;
            }
        }
    }
}